import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row } from "react-bootstrap";
import { PrismicRichText } from "@prismicio/react";
import Getdagcoins from "../components/get-dagcoins";
import Headertransparent from "../components/headertransparent";
import Layout from "../components/layout";
import { Seo } from "../components/seo";
import "../components/index.css";
import "../components/contact.css";

function ContactPage({ data }) {
  var SeoMeta = data.prismicPrivacyPolicy.data;

  var NextUid = data.prismicPrivacyPolicy.alternate_languages[0].uid;
  if (data.prismicPrivacyPolicy.lang === "en-us") {
    var NextUrl = "/et/" + NextUid;
  } else if (data.prismicPrivacyPolicy.lang === "et-ee") {
    NextUrl = "/" + NextUid;
  } else {
    NextUrl = "wrong lang";
  }
  return (
    <Layout htmllang={data.prismicPrivacyPolicy.lang}>
      <Headertransparent
        htmllang={data.prismicPrivacyPolicy.lang}
        langswitcher={NextUrl}
      />

      <Container className="above fullwidth contact-page regular-content-page">
        <GatsbyImage
          alt="Dagcoin"
          image={data.homebg.childImageSharp.gatsbyImageData}
        />
        <div className="flex">
          <Row className="homevideo"></Row>
          <Row>
            <h1>{SeoMeta.page_title.text}</h1>
          </Row>
        </div>
      </Container>
      <Container className="regular-content-page-c">
        <PrismicRichText field={SeoMeta.content.richText} />
      </Container>
    </Layout>
  );
}

export const Head = ({ data }) => {
  return (
    <>
      <Seo
        // TODO: Add description
        pathname={data.prismicPrivacyPolicy.uid}
        // TODO: Title should come from "seo_title" query
        title={data.prismicPrivacyPolicy.data.page_title.text}
        // lang={data.prismicPrivacyPolicy.lang}
      />
    </>
  );
};

export const query = graphql`
  query ($uid: String, $lang: String) {
    prismicPrivacyPolicy(uid: { eq: $uid }, lang: { eq: $lang }) {
      lang
      uid
      alternate_languages {
        id
        lang
        type
        uid
      }
      data {
        content {
          text
          richText
        }
        page_title {
          text
        }
      }
    }
    homebg: file(relativePath: { eq: "homebg.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    }
  }
`;
export default ContactPage;
